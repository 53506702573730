import React, { useState, useEffect } from "react";
import { useProfile } from "../../context/ProfileProvider";
import { Box, Container, Typography, List, ListItem, Checkbox, Button, Alert, Card } from "@mui/material";
import { useNav } from "../../context/NavProvider";

const CommunicationPreferences = () => {
    const { useGetContactPreference, postContactPreference, useGetProfile } = useProfile();
    const { contactPreference } = useGetContactPreference();
    const { party } = useGetProfile();
    const [selectedPreference, setSelectedPreference] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setShowFooter } = useNav();
    setShowFooter(false);

    useEffect(() => {
        const savedPreference = localStorage.getItem("contactPreference");
        if (savedPreference) {
            setSelectedPreference(savedPreference);
        } else if (contactPreference) {
            const initialPreference = contactPreference.toString().toLowerCase();
            setSelectedPreference(initialPreference !== "unknown" ? initialPreference : "");
        }
    }, [contactPreference]);

    const handleClick = () => {
        window.location.href = "/";
    };

    const handlePreferenceChange = (preference) => {
        setSelectedPreference(preference);
        setSuccess(false);
        localStorage.setItem("contactPreference", preference);
    };

    const handleUpdateContactPreference = async () => {
        setError(null);
        setSuccess(false);

        if (!party?.partyId) {
            setError("Debtor ID is required to update preferences.");
            return;
        }

        if (!selectedPreference) {
            setError("Please select a valid contact preference.");
            return;
        }

        try {
            const payload = {
                debtorId: party.partyId,
                newContact: selectedPreference,
            };
            const success = await postContactPreference(payload);
            if (!success) {
                throw new Error("Failed to update contact preferences.");
            }

            setSuccess(true);
        } catch (err) {
            setError(err.message || "An unknown error occurred.");
        }
    };

    return (
        <>
            <Container sx={{ mt: 4, mb: 7, paddingBottom: "550px" }}>
            <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}> Settings</Typography>
                </Box>
                <Typography paddingTop={1} paddingBottom={3} sx={{ color: "#555" }}>
                        Here, you can customise your contact preferences to suit your needs.
                    Update how you'd like to hear from us, and we'll ensure all communications
                    are tailored just for you.
                </Typography>
            </Box>
                <List sx={{ mb: 2 }}>
                {["email", "postal letter", "phone"].map((preference) => (
                    <Card key={preference} sx={{ marginBottom: 1 }}>
                        <ListItem >
                            <Checkbox sx={{ mr: 1 }}
                            checked={selectedPreference === preference}
                            onChange={() => handlePreferenceChange(preference)}
                        />
                        <Typography sx={{ textTransform: "capitalize" }}>{preference}</Typography>
                    </ListItem>
                    </Card>
                ))}
            </List>
            <Button
                onClick={handleUpdateContactPreference}
                sx={{
                    textTransform: "none",
                    backgroundColor: "primary.dark",
                    borderRadius: 0,
                    mr: 1,
                    color: "primary.contrastText",
                    "&:hover": {
                        backgroundColor: "primary.light",
                        color: "primary.dark",
                    },
                }}
            >
                Update contact preferences
                </Button>

                <Button sx={{
                    textTransform: "none",
                    width: { md: "20em" },
                    borderColor: "secondary.main",
                    border: '1px solid',
                    borderRadius: "0px",
                    color: "secondary.main",
                    ml: 2,
                    "&:hover": {
                        backgroundColor: "secondary.dark",
                        borderColor: "secondary.dark",
                        color: "primary.contrastText",
                    },
                }} onClick={handleClick}>Return Home</Button>
            {error && (
                <Alert severity="error" sx={{ mt: 1 }}>
                    {error}
                </Alert>
            )}
            {success && (
                <Alert severity="success" sx={{ mt: 1 }}>
                    Contact preferences updated successfully!
                </Alert>
                )}              
            </Container>
            {setShowFooter(true)}
        </>
    );
};

export default CommunicationPreferences;
