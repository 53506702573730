import React from "react";
import { useProfile } from "../../context/ProfileProvider";
import { useAccount } from "../../context/AccountProvider";
import { Box, Typography, Card, Container, Button, Stack } from "@mui/material";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useUtils } from "../../context/UtilsProvider";
import { useAuth } from "../../context/AuthProvider";
import useSWRMutation from "swr/mutation";
import { useNavigate } from "react-router-dom";

const Profile = () => {
    const { useGetAccount } = useAccount();
    const { account } = useGetAccount();
    const { useGetProfile } = useProfile();
    const { party } = useGetProfile();
    const navigate = useNavigate();
    const { accessToken, endSession, postSignOut } = useAuth();
    const { trigger } = useSWRMutation("/auth/signout", postSignOut);
    const { createAlert, alertSeverityTypes } = useUtils();
    const initials = `${party?.customerName.first.charAt(0)}${party?.customerName.last.charAt(0)}`;
    console.log('ACCOUNT DATA', party?.debtorId);
    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleSignOut = async (event) => {
        event.preventDefault();
        try {
            const response = await trigger("");
            console.info("post sign out response: ", response);
            endSession();
            createAlert("User sign out successful", alertSeverityTypes.success);
            navigate("/auth");
        } catch (error) {
            const message = "Oops! Something went wrong, please try again.";
            console.error("post sign out error", error);
            createAlert(message, alertSeverityTypes.error);
        }
    };

    return (
        <Container sx={{ mt: 4, mb: 7 }}>
            {/* Profile Header */}
            <Card sx={{ mb: 2, p: 2, textAlign: "center" }}>
                <Box
                    sx={{
                        backgroundColor: "primary.dark",
                        color: "primary.contrastText",
                        width: 60,
                        height: 60,
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mx: "auto",
                    }}
                >
                    <Typography variant="h6" component="span">
                        {initials}
                    </Typography>
                </Box>
                <Typography sx={{ fontWeight: 600, mt: 1, fontSize: "1.25rem" }}>
                    {party?.customerName.first} {party?.customerName.last}
                </Typography>
                <Typography sx={{ mt: 0.5, fontSize: "0.875rem", color: "text.secondary" }}>
                    Intrum reference number: {account?.accountReference}
                </Typography>
            </Card>

            {/* Action Cards */}
            <Card
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: '1px solid transparent',
                    p: 2,
                    mt: 0.5,
                    cursor: 'pointer',
                    '&:hover': {
                        borderColor: 'primary.dark'
                    }
                }}
                onClick={() => handleNavigation("../ContactPreferences")}
            >
                <Stack direction="row" alignItems="center" spacing={2}>
                    <PhonelinkSetupIcon sx={{ color: "primary.dark" }} />
                    <Box>
                        <Typography sx={{ fontWeight: 600 }}>Update Contact Information</Typography>
                        <Typography sx={{ fontSize: "0.875rem", color: "text.secondary" }}>
                            You can update your details here.
                        </Typography>
                    </Box>
                </Stack>
                <ChevronRightIcon />
            </Card>           

            {/* Logout Button */}
            {accessToken && (
                <Box sx={{ textAlign: "center", mt: 3 }}>
                    <Button
                        id="logout"
                        onClick={handleSignOut}
                        variant="outlined"
                        sx={{
                            textTransform: "none",
                            width: "10em",
                            borderColor: "primary.dark",
                            backgroundColor: "#fff",
                            borderRadius: 0,
                            color: "primary.dark",
                            "&:hover": {
                                backgroundColor: "primary.dark",
                                color: "primary.contrastText",
                                borderColor: "primary.dark",
                            },
                        }}
                    >
                        Log Out
                    </Button>
                </Box>
            )}
        </Container>
    );
};

export default Profile;
