import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import environment from '../env';
import useSWRImmutable from 'swr/immutable';
import { useAuth } from './AuthProvider';

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

const ProfileProvider = ({ children }) => {
    const { accessToken } = useAuth();
    const defaultOptions = {
        method: "GET",
        headers: {
            "x-api-key": environment.CUSTOMER_PORTAL_API_KEY,
            "Authorization": `Bearer ${accessToken}`,
        },
    };

    const baseUrl = environment.CUSTOMER_PORTAL_API;

    function useGetProfile() {
        const url = accessToken ? `${baseUrl}/party` : null;
        const { data, error, isLoading } = useSWRImmutable([url, defaultOptions]);
        return {
            party: data,
            error,
            isLoading,
        };
    }

    function useGetContactPreference() {
        const url = accessToken && `${baseUrl}/account/preferred-contact`;
        const { data, error, isLoading } = useSWRImmutable([url, defaultOptions]);
        return {
            contactPreference: data,
            error,
            isLoading,
        };
    }

    function useGetContactNumber() {
        const url = accessToken && `${baseUrl}/account/contact-number`;
        const { data, error } = useSWRImmutable([url, defaultOptions]);
        return {
            contactNumber: data,
            error,
        };
    }

    function useGetContactEmail() {
        const url = accessToken && `${baseUrl}/account/email-address`;
        const { data, error } = useSWRImmutable([url, defaultOptions]);
        return {
            emailAddress: data,
            error,
        };
    }

    async function postEmailAddress(email) {
        const url = accessToken && `${baseUrl}/account/save-email-address`;
        const requestOptions = {
            ...defaultOptions,
            method: "POST",
            body: JSON.stringify(email),
        };
        try {
            const response = await fetch(url, requestOptions);
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    async function postContactNumber(contactNumber) {
        const url = accessToken && `${baseUrl}/account/save-contact-number`;
        const requestOptions = {
            ...defaultOptions,
            method: "POST",
            body: JSON.stringify(contactNumber),
        };
        try {
            const response = await fetch(url, requestOptions);
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    async function postContactPreference(contactPreference) {
        const url = accessToken && `${baseUrl}/account/save-preferred-contact`;
        const requestOptions = {
            ...defaultOptions,
            method: "POST",
            body: JSON.stringify(contactPreference),
        };
        try {
            const response = await fetch(url, requestOptions);
            return response.ok;
        } catch (error) {
            return false;
        }
    }

    const contextValue = useMemo(() => ({
        useGetProfile,
        useGetContactNumber,
        useGetContactPreference,
        useGetContactEmail,
        postEmailAddress,
        postContactNumber,
        postContactPreference
    }), [useGetProfile, useGetContactNumber, useGetContactPreference, useGetContactEmail, postEmailAddress, postContactNumber, postContactPreference]);

    return (
        <ProfileContext.Provider value={contextValue}>
            {children}
        </ProfileContext.Provider>
    );
};

ProfileProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProfileProvider;