import environment from "../env";
import { createTheme } from "@mui/material";
import { createContext, useContext, useState, useEffect } from "react";

import useSWRImmutable, { SWRConfig } from "swr/immutable";

import { useCookie } from "./CookieProvider";

// Create context object and make available through custom hook
const ClientContext = createContext();
export const useClient = () => useContext(ClientContext);

const ClientProvider = ({ children }) => {
  const { setCookie, getCookie } = useCookie();

  const [registrationStatus, setRegistrationStatus] = useState(null);
  // TODO: Specify local brand images here until CDN solution
  const [clientLogo] = useState("images/small_logo.png");
  const [bannerBackground] = useState("images/banner_background.png");
  const [clientTheme, setClientTheme] = useState(null);

  // Layout data
  const [layout, setLayout] = useState(null);

  // Maintenance mode
  const [ maintenanceMode, setMaintenanceMode ] = useState(false);

  // Client contact us data
  const [intro, setIntro] = useState([
    `Need assistance with your account, have questions about our services, or just want to chat?
     Our team at Vanilla Finance is here to help. Whether you prefer to reach out via phone, email,
     or writing to us, we're ready to assist you with any inquiries or concerns you may have.`,
    `Don't hesitate to get in touch, we're committed to providing you with the support you need.
     We look forward to hearing from you!`,
  ]);
  const [openingHours, setOpeningHours] = useState([
    { days: "Monday - Friday", time: "08:00am to 20:00pm" },
    { days: "Saturday", time: "09:00am to 13:30pm" },
    { days: "Public Holidays", time: "Closed" },
  ]);
  const [clientEmail, setClientEmail] = useState("myaccount.uk@intrum.com");
  const [clientTelephoneNumber, setClientTelephonNumber] =
    useState("+44 1737 237 370");
  const clientAddress = [
    `Intrum UK Limited`,
    `The Omnibus Building`,
    `Lesbourne Road`,
    `Reigate`,
    `Surrey`,
    `RH2 7JP`,
  ];
  const clientSortCode = "60-00-01";
  const clientAccountNumber = "40524639";

  /*const setClient = (registration, theme, layout, cookies=false) => {
    // Set client state
    setRegistrationStatus(registration);
    setClientTheme(createTheme(theme));
    setLayout(layout);

    // Set cookies if required
    if (cookies) {
      setCookie("registration", registration, 1, "/");
      setCookie("theme", JSON.stringify(theme), 1, "/");
      setCookie("layout", JSON.stringify(layout), 1, "/");
    }
  }*/

  // Settlement config: Replace later in client manager data
  const [settlementConfig, setSettlementConfig] = useState({
    required: true,
  });

  // My Profile switch
  const [disableProfile, setDisableProfile] = useState({
    required: false,
  });

  // Hook to call API proxy to client manager config
  function useGetConfig() {
    const url = `${environment.CUSTOMER_PORTAL_API}/config/${environment.CLIENT_NAME}`;
    const options = {
      method: "GET",
      headers: { "x-api-key": environment.CUSTOMER_PORTAL_API_KEY },
    };
    console.info("client config request", { options, url });
    const { data, error, isLoading } = useSWRImmutable([url, options]);
    console.info("client config response", { data, error, isLoading });
    return {
      clientConfig: data,
      error,
      isLoading,
    };
  }

  /*const registrationCookie = getCookie("registration");
  const themeCookie = getCookie("theme");
  const layoutCookie = getCookie("layout");
  if (registrationCookie && themeCookie && layoutCookie) {
    setClient(registrationCookie, JSON.parse(themeCookie), JSON.parse(layoutCookie));
  } else {
    setClient(clientConfig?.registration , clientConfig?.theme, clientConfig?.layout, !isLoading);
  }*/

  const contextValue = {
    registrationStatus,
    clientLogo,
    bannerBackground,
    clientTheme,
    intro,
    openingHours,
    clientEmail,
    clientTelephoneNumber,
    clientAddress,
    clientSortCode,
    clientAccountNumber,
    layout,
    maintenanceMode,
    setMaintenanceMode,
    useGetConfig,
    settlementConfig,
    disableProfile
  };

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
