import { useState, useEffect, createContext, useContext } from "react";
import { useArrangement } from "./ArrangementProvider";
import { useAuth } from "./AuthProvider";
import environment from '../env';

const NavContext = createContext();
export const useNav = () => useContext(NavContext);

const NavProvider = ({ children }) => {
  const { accessToken } = useAuth();
  const { hasArrangement } = useArrangement();
  const [navigation, setNavigation] = useState(null);
  const [showFooter, setShowFooter] = useState(false);
  const apiUrl = environment.CUSTOMER_PORTAL_API;
  const apiKey = environment.CUSTOMER_PORTAL_API_KEY;

  const defaultOptions = {
    headers: {
      "x-api-key": apiKey,
      "Authorization": `Bearer ${accessToken}`,
      "organisation": environment.CLIENT_NAME,
    }
  };

  useEffect(() => {
    setNavigation([
      {
        name: "My Financial Statement",
        id: "nav-sfs",
        href: "/sfs",
        current: false,
        hide: false,
      },
      {
        name: "Transactions",
        id: "transaction-history",
        href: "/transactionhistory",
        current: false,
        hide: false,
      },
      {
        name: "Manage Saved Cards",
        id: "manage-cards",
        href: "/managecards",
        current: false,
        hide: false,
      },
    ]);
  }, [hasArrangement]);

  async function postAnalytics(request) {
    if (!accessToken) return;

    try {
      const response = await fetch(`${apiUrl}/analytics`, {
        ...defaultOptions,
        method: "POST",
        body: JSON.stringify(request),
      });
      const data = await response.json();
      console.log("Post analytics response:", data);
    } catch (error) {
      console.error("Error posting analytics:", error);
    }
  }

  const contextValue = {
    navigation,
    settings: [{ name: "Sign out", id: "logout", href: "#" }],
    showFooter,
    setShowFooter,
    postAnalytics,
  };

  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
};

export default NavProvider;
