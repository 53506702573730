import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Card,
    CardContent,
    Button,
    TextField,
    CircularProgress,
    Alert
} from '@mui/material';
import { useProfile } from '../../context/ProfileProvider';
import { useNav } from "../../context/NavProvider";
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
}));

const ContactPreferences = () => {
    const { setShowFooter } = useNav();
    useEffect(() => {
        setShowFooter(false);
    }, [setShowFooter]);

    const {
        useGetProfile,
        useGetContactNumber,
        postContactNumber,
        useGetContactEmail,
        postEmailAddress,
    } = useProfile();

    const { party } = useGetProfile();
    const { contactNumber } = useGetContactNumber();
    const { emailAddress } = useGetContactEmail();
    const [editingField, setEditingField] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [homeNumber, setHomeNumber] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [errorHome, setErrorHome] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [successHome, setSuccessHome] = useState(false);
    const [successEmail, setSuccessEmail] = useState(false);
    const [savedHomeNumber, setSavedHomeNumber] = useState('');
    const [savedEmailAddress] = useState('');

    useEffect(() => {
        if (contactNumber) {
            const home = contactNumber.find((c) => c.type === 'Home');
            if (home) setHomeNumber(home.number || '');
        }
    }, [contactNumber]);

    const customerFullName = () => {
        return `${party?.customerName.prefix} ${party?.customerName.first} ${party?.customerName.last}`;
    };

    const createContactPayload = (type, typeId, number) => ({
        accountId: party?.accountId,
        applyPreferences: false,
        consent: {
            phoneId: null,
            debtorName: null,
            phoneNumber: null,
            mayCall: true,
            mayCallViaDialer: true,
            mayFax: true,
            maySms: true,
            writtenConsent: null,
            obtainedFrom: customerFullName(),
            comment: null
        },
        correspondence: false,
        debtorName: customerFullName(),
        extension: null,
        name: '',
        number: number || 'No Number',
        onHold: false,
        partyId: party?.partyId,
        partyName: customerFullName(),
        phonesPreferences: { "sundayDoNotCall": false, "mondayDoNotCall": false, "tuesdayDoNotCall": false, "wednesdayDoNotCall": false, "thursdayDoNotCall": false, "fridayDoNotCall": false, "saturdayDoNotCall": false },
        status: '',
        statusId: 2,
        type,
        typeId,
    });

    const handleClick = () => {
        window.location.href = "/"
    }

    const handleSaveClick = async (type, typeId, number) => {
        setIsSaving(true);
        resetStatusMessages();

        if (!number.trim()) {
            handleError(`Please enter a ${type.toLowerCase()} number.`, type);
            return;
        }

        if (!validateNumber(type, number)) {
            setIsSaving(false);
            return;
        }

        try {
            const success = await postContactNumber(createContactPayload(type, typeId, number));

            if (success) {
                handleSuccess(type, number);
            } else {
                handleError(`Failed to update ${type.toLowerCase()} number.`, type);
            }
        } catch (err) {
            handleError(`Error updating ${type.toLowerCase()} number. Please try again later.`, type);
        } finally {
            setIsSaving(false);
        }
    };

    const handleEditClick = () => {
        setSavedHomeNumber(homeNumber);
        setEditingField('Home');
    };

    const handleCancelClick = () => {
        setEditingField('');
        setHomeNumber(savedHomeNumber)
        resetStatusMessages()
    }

    const handleCancelEmailClick = () => {
        setEditingField('');
        setNewEmail(savedEmailAddress);
        resetStatusMessages();
    }

    const handleError = (message, type) => {
        if (type === 'Home') setErrorHome(message);
        setIsSaving(false);
    };

    const handleSuccess = (type, number) => {
        if (type === 'Home') {
            setHomeNumber(number);
            setSuccessHome(true);
        }
        setEditingField('');
    };

    const resetStatusMessages = () => {
        setSuccessHome(false);
        setErrorHome('');
        setSuccessEmail(false);
        setErrorEmail('');
    };

    const savedNumbers = new Set();

    const isValidHomeNumber = (number) => {
        return /^0\d{10}$/.test(number) && new Set(number).size !== 1;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validateNumber = (type, number) => {
        let errorMessage = "";

        if (savedNumbers.has(number)) {
            errorMessage = `${type} number has already been saved.`;
            if (type === "Home") setErrorHome(errorMessage);
            return false;
        }
        if (type === "Home") {
            if (!isValidHomeNumber(number)) {
                errorMessage = "Phone numbers must be 11 digits, start with 0, and not be the same number repeated.";
                setErrorHome(errorMessage);
                return false;
            }
        }

        savedNumbers.add(number);
        return true;
    };

    const email = Array.isArray(emailAddress) && emailAddress.length > 0 && emailAddress[0]?.email
        ? emailAddress[0].email
        : "No Email Address";

    const validateEmail = (email) => {
        if (!isValidEmail(email)) {
            setErrorEmail("Please enter a valid email address.");
            setIsSaving(false);
            return false;
        }
        return true;
    };

    const handleSaveEmailClick = async () => {
        setIsSaving(true);
        setErrorEmail('');
        setSuccessEmail(false);

        if (!newEmail.trim()) {
            setErrorEmail('Please enter an email address.');
            setIsSaving(false);
            return;
        }

        if (!validateEmail(newEmail)) {
            setErrorEmail("Please enter a valid email address.");
            setIsSaving(false);
            return;
        }

        try {
            const emailInfo = {
                accountId: party?.accountId,
                consentGiven: true,
                consentSourceId: party?.partyId,
                consentSource: customerFullName(),
                debtorId: party?.partyId,
                debtorName: customerFullName(),
                email: newEmail,
                primary: true,
                statusCd: 'Good',
                typeCd: 'Home',
                writtenConsent: false,
                active: true,
                type: 'Home',
            };

            const success = await postEmailAddress(emailInfo);
            if (success) {
                setEditingField('');
                party.email = emailInfo.email;
                setNewEmail('');
                setSuccessEmail(true);
            } else {
                setErrorEmail('Failed to update email address.');
            }
        } catch (error) {
            setErrorEmail('Error updating email address. Please try again later.');
        } finally {
            setIsSaving(false);
        }
    };
    console.log('whats in here?', party?.email, party)
    return (
        <>
            <Container sx={{ mt: 4, mb: 7, paddingBottom: "550px" }}>
                <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>Update Contact Information</Typography>
                    </Box>
                    <Typography paddingTop={1} paddingBottom={3} sx={{ color: '#555' }}>
                        Keep your contact information up to date to never miss an important update.
                    </Typography>
                </Box>
                <StyledCard>
                    <CardContent sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ width: '70%' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Phone Number:</Typography>
                                {editingField === 'Home' ? (
                                    <TextField
                                        margin='dense'
                                        type="number"
                                        sx={{ mt: 1, width: '190px' }}
                                        value={homeNumber}
                                        label='Enter your new phone number *'
                                        onChange={(e) => setHomeNumber(e.target.value)}
                                        inputProps={{ maxLength: 11 }}
                                    />
                                ) : (
                                        <Typography variant="body2">{homeNumber || 'No Phone Number'}</Typography>
                                )}
                                {errorHome && <Alert severity="error" sx={{ mt: 1 }}>{errorHome}</Alert>}
                                {successHome && <Alert severity="success" sx={{ mt: 1 }}>Phone number updated successfully!</Alert>}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', mt: 2 }}>
                                {editingField !== 'Home' ? (
                                    <Button variant="text" onClick={handleEditClick}>Edit</Button>
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                                sx={{ ml: 1, mr: 1, backgroundColor: "primary.main" }}
                                            onClick={() => handleSaveClick('Home', 1, homeNumber)}
                                            disabled={isSaving || !homeNumber || homeNumber === contactNumber?.find(c => c.type === 'Home')?.number}
                                        >
                                            {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                                        </Button>
                                            <Button variant="text" onClick={handleCancelClick}>Cancel</Button>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </StyledCard>

                <StyledCard>
                    <CardContent sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ width: '70%' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Email Address:</Typography>
                                {editingField === 'Email' ? (
                                    <TextField
                                        value={newEmail}
                                        type='text'
                                        margin='dense'
                                        sx={{ mt: 1, width: '190px', '&:focus': { outline: 'none', boxShadow: 'none' } }}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        label='Enter your new email address *'
                                        inputProps={{ maxLength: 50 }}
                                    />
                                ) : (
                                        <Typography variant="body2">{email}</Typography>
                                )}
                                {errorEmail && <Alert severity="error" sx={{ mt: 1 }}>{errorEmail}</Alert>}
                                {successEmail && <Alert severity="success" sx={{ mt: 1 }}>Email address updated successfully!</Alert>}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', mt: 2 }}>
                                {editingField !== 'Email' ? (
                                    <Button variant="text" onClick={() => setEditingField('Email')}>Edit</Button>
                                ) : (
                                    <>
                                        <Button
                                            variant="contained"
                                                sx={{ marginLeft: 1, marginRight: 1, backgroundColor: "primary.main" }}
                                            onClick={handleSaveEmailClick}
                                            disabled={isSaving || !newEmail || newEmail === party?.email}
                                        >
                                            {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                                        </Button>
                                            <Button variant="text" onClick={handleCancelEmailClick}>Cancel</Button>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </StyledCard>
                <Button sx={{
                    textTransform: "none",
                    mt: 2,
                    width: { md: "20em", xs: "25em" },
                    marginLeft: { md: "0px", xs: "24px" },
                    backgroundColor: { xs: "primary.main", md: "primary.dark" },
                    borderRadius: "0px",
                    color: "primary.contrastText",
                    "&:hover": {
                        backgroundColor: "primary.light",
                        color: "primary.dark",
                    },
            }} onClick={handleClick}>Return Home</Button>
            </Container>

            {setShowFooter(true)}
        </>
    );
};

export default ContactPreferences;
